require('../css/font.css');
require('../css/style.css');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {

    document.body.addEventListener('click', e => {

        if (e.target.classList.contains('top-tab-a')) {
            e.preventDefault();
            switchTab(e.target);
        } else if (e.target.classList.contains('faq-btn') || e.target.classList.contains('faq-title')) {
            toggleFaq(e.target, {
                collapseOthers: true
            });
        } else if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
        } else if (e.target.classList.contains('read-more-1') || e.target.classList.contains('collapse-1')) {

            // Read more 1

            toggleReadMore(e.target, {
                parent: '.intro-entry',
                area: '.m-slide-down',
                wrapper: '.m-slide-down-measure',
                onCollapsed: (clicked, options) => {
                    setTimeout(() => {
                        clicked.classList.remove('m-visible');
                        clicked.classList.add('m-hidden');

                        const btnExpand = clicked.closest(options.parent).querySelector('.read-more-1');
                        btnExpand.closest('.m-readmore-btn-wrapper').querySelector('.dots').textContent = '...';
                        btnExpand.classList.remove('m-hidden');
                    }, 450);
                },
                onExpanded: (clicked, options) => {
                    clicked.classList.remove('m-visible');
                    clicked.classList.add('m-hidden');
                    clicked.closest('.m-readmore-btn-wrapper').querySelector('.dots').textContent = '.';
                    clicked.closest(options.parent).querySelector('.collapse-1').classList.remove('m-hidden');
                }
            });
        } else if (e.target.classList.contains('read-more-2') || e.target.classList.contains('collapse-2')) {

            // Read more 2

            toggleReadMore(e.target, {
                parent: '.t-top-title',
                area: '.m-slide-down',
                wrapper: '.m-slide-down-measure',
                onCollapsed: (clicked, options) => {
                    setTimeout(() => {
                        clicked.classList.remove('m-visible');
                        clicked.classList.add('m-hidden');

                        const btnExpand = clicked.closest(options.parent).querySelector('.read-more-2');
                        btnExpand.closest('.m-readmore-btn-wrapper').querySelector('.dots').textContent = '...';
                        btnExpand.classList.remove('m-hidden');
                    }, 450);
                },
                onExpanded: (clicked, options) => {
                    clicked.classList.remove('m-visible');
                    clicked.classList.add('m-hidden');
                    clicked.closest('.m-readmore-btn-wrapper').querySelector('.dots').textContent = '.';
                    clicked.closest(options.parent).querySelector('.collapse-2').classList.remove('m-hidden');
                }
            });
        } else if (e.target.classList.contains('read-more-faq')) {

            // Read more 3

            toggleReadMore(e.target, {
                parent: '.faq-listing',
                area: '.m-slide-down',
                wrapper: '.m-slide-down-measure',
                text: 'self',
                textCollapsed: 'Show more FAQs',
                textExpanded: 'Hide FAQs'
            });
        }
    });
}

function switchTab(a) {
    if (isMobile()) {
        const parent = a.closest('ul');
        const idx = Array.from(parent.children).indexOf(a.parentElement);

        mobileSliderSet(idx);
        mobileSliderPositionActive();
    } else {
        const parentLi = a.closest('li');
        const tabId = a.href.slice(a.href.lastIndexOf('#') + 1);
        document.querySelectorAll('.mytabs').forEach(tab => tab.classList.remove('active'));
        document.querySelectorAll('.t-top-tabs li').forEach(li => li.classList.remove('active'));
        document.getElementById(tabId).classList.add('active');
        parentLi.classList.add('active');
    }
}

function scrollTo(e) {

    e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
}

function toggleFaq(faqBtn, options = {}) {
    const faq = faqBtn.closest('.faq');
    if (faq.classList.contains('collapsed')) {

        if (Object(options).collapseOthers) {
            const wrapper = faqBtn.closest('.faq-wrapper');
            const faqs = wrapper.querySelectorAll('.faq');
            if (faqs.length) {
                faqs.forEach(item => {
                    item.classList.remove('expanded');
                    item.classList.add('collapsed');
                });
            }
        }

        faq.classList.remove('collapsed');
        faq.classList.add('expanded');
    } else {
        faq.classList.remove('expanded');
        faq.classList.add('collapsed');
    }
}

function toggleReadMore(clicked, options) {

    const toggleArea = clicked.closest(options.parent).querySelector(options.area);

    if (toggleArea.clientHeight) {
        toggleArea.style.height = 0;
        if (options.text) {
            const text = options.textCollapsed || 'Read More';
            options.text === 'self'
                ? clicked.textContent = text
                : clicked.querySelector(options.text).textContent = text;
        }
        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(0deg)';

        if (typeof options.onCollapsed === 'function') {
            options.onCollapsed.call(null, clicked, options);
        }
    } else {

        const wrapper = toggleArea.querySelector(options.wrapper || 'div');

        // Handle any nested read mores.
        // This will trigger when device is mobile and there is nested desktop read more.
        if (Array.isArray(options.nested)) {
            options.nested.forEach(selector => {
                const nestedAreas = wrapper.querySelectorAll(selector);
                if (nestedAreas.length) {
                    nestedAreas.forEach(nestedArea => nestedArea.style.height = 'auto');
                }
            });
        }

        toggleArea.style.height = wrapper.clientHeight + 'px';

        if (options.text) {
            const text = options.textExpanded || 'Collapse';
            options.text === 'self'
                ? clicked.textContent = text
                : clicked.querySelector(options.text).textContent = text;
        }

        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(180deg)';

        if (typeof options.onExpanded === 'function') {
            options.onExpanded.call(null, clicked, options);
        }
    }
}

function isMobile() {
    const detector = document.querySelector('.mobile-detector');
    const styles = window.getComputedStyle(detector);
    return styles.getPropertyValue('display') === 'none';
}